<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="formMarginBtm20">
        <FormItem>
          <span class="label">厂家名称：</span>
          <Select filterable clearable v-model="querryList.search_str" class="iviewIptWidth250">
            <Option v-for="item in nameList" :value="item.factory_name" :key="item.id">{{ item.factory_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">营业执照有效期：</span>
          <DatePicker
            @on-change="
              e => {
                this.querryList.business_date_start = e
              }
            "
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewwidth200px"
          ></DatePicker>
          <span class="centeFont">至</span>
          <DatePicker
            @on-change="
              e => {
                this.querryList.business_date_end = e
              }
            "
            format="yyyy-MM-dd"
            type="date"
            class="iviewwidth200px marginRight90"
            placeholder="请选择"
          ></DatePicker>
        </FormItem>
        <FormItem>
          <span class="label">状态：</span
          ><Select clearable v-model="querryList.state" class="iviewIptWidth250">
            <Option v-for="item in statusCode" :value="item.code" :key="item.code">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="10">
          <span class="pageBtn finger btnSure" @click="search">查询</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="addzFacotryModel = true" v-if="showChange == 1" v-show="insertAble">新增</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table @on-sort-change="sort" v-if="this.clickIndex == 1" :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :isLoad="isLoad" totalText="条记录"> </Table>
      <Table v-if="this.clickIndex == 2" :productList="listColumns2" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :isLoad="isLoad" totalText="条记录"> </Table>
    </div>
    <!-- 选择厂家类型 -->
    <Modal v-model="addzFacotryModel" title="请选择厂家类型" :footer-hide="true">
      <RadioGroup v-model="facttype" class="RadioGroup">
        <Radio label="1">
          <span>国内厂家</span>
        </Radio>
        <Radio label="2">
          <span>境外厂家</span>
        </Radio>
      </RadioGroup>
      <div class="foot clearfix">
        <span class="pageBtn finger btnSure fr marginLeft20" @click="ok">确定</span>
        <span class="pageBtn finger btnCancle fr" @click="addzFacotryModel = false">取消</span>
      </div>
    </Modal>

    <!-- 删除模态框 -->
    <!-- <Modal v-model="DeletStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认删除？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="SureDelet">确定</span>
          <span class="pageBtn finger btnCancle" @click="DeletStatus = false">取消</span>
        </div>
      </div>
    </Modal> -->
    <tips-component showModal="delete" v-if="DeletStatus" @cancleBtn="DeletStatus = false" @sureBrn="SureDelet"></tips-component>
  </div>
</template>

<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'factoryManage',
  components: {
    Table,
    TipsComponent
  },
  data() {
    return {
      // 被删除厂家id
      deletID: '',
      // 控制删除
      DeletStatus: false,
      facttype: '',
      // 控制 选择厂家类型 对话框
      addzFacotryModel: false,
      isLoad: false,
      // 控制 国内厂家 境外厂家 tab切换
      navFrist: [
        {
          name: '国内厂家',
          num: 140,
          type: 1
        },
        {
          name: '境外厂家',
          num: 40,
          type: 2
        }
      ],
      clickIndex: 1,
      // 控制 国内厂家
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 55,
          maxWidth: 80
        },
        {
          title: '厂家编号',
          key: 'factory_num',
          align: 'center',
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont'
                },
                param.row.factory_num
              )
            ])
          },
          minWidth: 140
        },
        {
          title: '厂家名称',
          key: 'factory_name',
          align: 'center',
          minWidth: 320,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      // 传递一个工厂id值
                      this.goDetails(param.row.factory_id, this.Byexamination, param.row.subfactory_id)
                    }
                  }
                },
                param.row.factory_name
              )
            ])
          }
        },
        {
          title: '营业执照有效期',
          key: 'factory_business',
          align: 'center',
          minWidth: 110,
          maxWidth: 150,
          sortable: 'custom',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont'
                },
                param.row.factory_business
              )
            ])
          }
        },
        {
          title: '生产许可有效期',
          key: 'factory_product',
          align: 'center',
          sortable: 'custom',
          minWidth: 110,
          maxWidth: 170,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont'
                },
                param.row.factory_product
              )
            ])
          }
        },
        {
          title: '备案日期',
          key: 'factory_filing',
          align: 'center',
          minWidth: 100,
          sortable: 'custom',
          maxWidth: 140,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont'
                },
                param.row.factory_filing
              )
            ])
          }
        },
        {
          title: '状态',
          key: 'factory_state',
          align: 'center',
          minWidth: 70,
          maxWidth: 130,
          render: (h, param) => {
            let status = param.row.factory_state
            switch (status) {
              case '待审核':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                      on: {
                        click: () => {}
                      }
                    },
                    '待审核'
                  )
                ])
               case '禁用':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                      on: {
                        click: () => {}
                      }
                    },
                    '禁用'
                  )
                ])
              case '审核驳回':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                      on: {
                        click: () => {}
                      }
                    },
                    '审核驳回'
                  )
                ])
              case '审核通过':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                      on: {
                        click: () => {
                          this.goDetails(param.row.factory_id, this.Byexamination, param.row.subfactory_id)
                        }
                      }
                    },
                    '审核通过'
                  )
                ])
              case '草稿':
                return h('div', [
                  h(
                    'span',
                    {
                      class: 'tableBlakcFont',
                      on: {
                        click: () => {
                          this.goDetails(param.row.factory_id, this.Byexamination, param.row.subfactory_id)
                        }
                      }
                    },
                    '草稿'
                  )
                ])
            }
          }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          minWidth: 100,
          maxWidth: 150,
          render: (h, param) => {
            let status = param.row.factory_state
            if (this.showChange == 1) {
              switch (status) {
                case '审核通过':
                  return h('div', [
                    h(
                      'span',
                      {
                        // class: 'tableFont',
                        class: {
                          disableUse: this.modifyAble,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            this.goDetails(param.row.factory_id, this.Byexamination, param.row.subfactory_id)
                          }
                        }
                      },
                      // param.row.operation[0]
                      '编辑'
                    ),
                    h(
                      'span',
                      {
                        class: 'tableFont tableLeft',
                        on: {
                          click: () => {
                            this.exportExcel(param.row)
                          }
                        }
                      },
                      // param.row.operation[1]
                      '导出审批表'
                    )
                  ])
                case '审核驳回':
                  return h('div', [
                    h(
                      'span',
                      {
                        // class: 'tableFont',
                        class: {
                          disableUse: this.modifyAble,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            this.goDetails(param.row.factory_id, this.Byexamination, param.row.subfactory_id)
                          }
                        }
                      },
                      // param.row.operation[0]
                      '编辑'
                    ),
                    // h(
                    //   'span',
                    //   {
                    //     class: 'tableFont tableLeft',
                    //     on: {
                    //       click: () => {
                    //         this.DeletStatus = true
                    //         this.deletID = param.row.factory_id
                    //       }
                    //     }
                    //   },
                    //   // param.row.operation[1]
                    //   '删除'
                    // )
                  ])
                case '待审核':
                  return h('div', [
                    h(
                      'span',
                      {
                        // class: 'tableFont',
                        class: {
                          disableUse: this.modifyAble,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            this.goDetails(param.row.factory_id, this.Byexamination, param.row.subfactory_id)
                          }
                        }
                      },
                      // param.row.operation[0]
                      '编辑'
                    )
                  ])
                case '禁用':
                  return h('div', [
                    h(
                      'span',
                      {
                        // class: 'tableFont',
                        class: {
                          disableUse: false,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            // this.goDetails(param.row.factory_id, this.Byexamination, param.row.subfactory_id)
                          }
                        }
                      },
                      // param.row.operation[0]
                      '-'
                    )
                  ])
                case '草稿':
                  return h('div', [
                    h(
                      'span',
                      {
                        // class: 'tableFont',
                        class: {
                          disableUse: this.modifyAble,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            this.goDetails(param.row.factory_id, this.Byexamination, param.row.subfactory_id)
                          }
                        }
                      },
                      '编辑'
                    ),
                    // h(
                    //   'span',
                    //   {
                    //     class: 'tableFont tableLeft',
                    //     on: {
                    //       click: () => {
                    //         this.DeletStatus = true
                    //         this.deletID = param.row.factory_id
                    //       }
                    //     }
                    //   },
                    //   '删除'
                    // )
                  ])
              }
            } else {
              switch (status) {
                case '审核通过':
                  return h('div', [
                    h(
                      'span',
                      {
                        class: 'tableFont tableLeft',
                        on: {
                          click: () => {
                            this.exportExcel(param.row)
                          }
                        }
                      },
                      // param.row.operation[1]
                      '导出审批表'
                    )
                  ])
                default:
                  return h('div', [
                    h(
                      'span',
                      {
                        style: {
                          color: '#389AFC',
                          fontSize: '14px'
                        }
                      },
                      '-'
                    )
                  ])
              }
            }
          }
        }
      ],
      // 控制 境外厂家
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 55,
          maxWidth: 80
        },
        {
          title: '厂家编号',
          key: 'factory_num',
          align: 'center',
          minWidth: 140,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont'
                },
                param.row.factory_num
              )
            ])
          }
        },
        {
          title: '厂家名称',
          key: 'factory_name',
          align: 'center',
          minWidth: 140,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.goOutland(param.row.factory_id, param.row.subfactory_id, 1)
                    }
                  }
                },
                param.row.factory_name
              )
            ])
          }
        },
        {
          title: '状态',
          key: 'factory_state',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont'
                },
                param.row.factory_state
              )
            ])
          }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          render: (h, param) => {
            let status = param.row.factory_state
            switch (status) {
              case '审核通过':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        disableUse: this.modifyAble,
                        tableFont: true
                      },
                      on: {
                        click: () => {
                          this.goOutland(param.row.factory_id, param.row.subfactory_id, 0)
                        }
                      }
                    },
                    '编辑'
                  )
                  // h(
                  //   'span',
                  //   {
                  //     class: 'tableFont tableLeft',
                  //     on: {
                  //       click: () => {
                  //         this.exportExcel(param.row)
                  //       }
                  //     }
                  //   },
                  //   '导出审批表'
                  // )
                ])
              case '审核驳回':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        disableUse: this.modifyAble,
                        tableFont: true
                      },
                      on: {
                        click: () => {
                          this.goOutland(param.row.factory_id, param.row.subfactory_id, 0)
                        }
                      }
                    },
                    '编辑'
                  ),
                  // h(
                  //   'span',
                  //   {
                  //     class: 'tableFont tableLeft',
                  //     on: {
                  //       click: () => {
                  //         this.DeletStatus = true
                  //         this.deletID = param.row.factory_id
                  //       }
                  //     }
                  //   },
                  //   '删除'
                  // )
                ])
              case '待审核':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        disableUse: this.modifyAble,
                        tableFont: true
                      },
                      on: {
                        click: () => {
                          this.goOutland(param.row.factory_id, param.row.subfactory_id, 0)
                        }
                      }
                    },
                    // param.row.operation[0]
                    '编辑'
                  )
                ])
              case '草稿':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        disableUse: this.modifyAble,
                        tableFont: true
                      },
                      on: {
                        click: () => {
                          this.goOutland(param.row.factory_id, param.row.subfactory_id, 0)
                        }
                      }
                    },
                    '编辑'
                  ),
                  // h(
                  //   'span',
                  //   {
                  //     class: 'tableFont tableLeft',
                  //     on: {
                  //       click: () => {
                  //         this.DeletStatus = true
                  //         this.deletID = param.row.factory_id
                  //       }
                  //     }
                  //   },
                  //   '删除'
                  // )
                ])
            }
          }
        }
      ],
      // 和Table 绑定 用来存储 发送请求  获取厂家列表之后的数据
      listData: [],
      // 绑定Table 页面总数
      total: 1,
      // 绑定Table 页码信息
      pages: {
        page: 1,
        rows: 10
      },
      // 调接口获取 厂家列表
      nameList: [],
      // 状态下拉列表 数据
      statusCode: [
        { name: '草稿', code: '-1' },
        { name: '审核通过', code: '1' },
        { name: '审核驳回', code: '2' },
        { name: '待审核', code: '3' }
      ],
      // 发送请求提交的对象列表  用来存储数据的对象
      querryList: {
        page: 1,
        rows: 10,
        search_str: '',
        business_data_start: '',
        business_date_end: '',
        state: '',
        type: 1 // 厂家类型 1=国内厂家 2=境外厂家
      },
      Byname: '1',
      Byexamination: '0',
      showChange: 1,
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false // 查找权限
    }
  },
  activated () {
    if(sessionStorage.getItem('updateList') == 0) {
      this.getSearchProductFactory()
      sessionStorage.setItem('updateList','1')
    }
    
  },
  // 创建页面的时候
  mounted() {
    this.showChange = localStorage.getItem('showChange') || 2
    this.test()
    this.getSearchProductFactory()
    this.getListSupList()
    this.getRightVisibal()
  },
  methods: {
    // column：当前列数据  key：排序依据的指标  order：排序的顺序，值为 asc 或 desc
    // factory_num编号
    // business_license营业执照
    // product_license生产许可
    // filing_license备案凭证
    sort(data) {
      console.log(data)
      this.key = data.key
      this.order = data.order
      this.getSearchProductFactory()
    },
    // 导出审批表 调用函数
    // 导出审批表操作
    exportExcel(row) {
      console.log(row)
      this.$http.downFile(this.$api.exportFactory, { subfactory_id: row.subfactory_id }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '厂家审批表' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 确认删除
    SureDelet() {
      this.DeletStatus = false
      let data = {
        factory_id: this.deletID
      }
      this.$http.put(this.$api.factoryDelete, data, false).then(res => {
        this.getSearchProductFactory()
        this.getListSupList()
      })
    },
    // 点击编辑触发事件
    goDetails(id, status, subfactory_id) {
      this.$router.push({
        path: '/manufacturerManage',
        query: {
          id: id,
          status: status,
          subfactory_id: subfactory_id
        }
      })
    },

    //
    goOutland(id, subfactory_id, status) {
      this.$router.push({
        path: '/outlandManage',
        query: {
          id: id,
          subfactory_id: subfactory_id,
          status: status
        }
      })
    },
    getListSupList() {
      this.$http.get(this.$api.supplierFactoryList, true).then(res => {
        this.nameList = res.data
      })
    },
    search() {
      this.pages.page = 1
      this.getSearchProductFactory()
    },
    test() {
      if (this.$route.query.show) {
        this.querryList.state = '3'
      }
    },
    // 产品厂家
    getSearchProductFactory() {
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
        search_str: this.querryList.search_str,
        business_date_start: this.querryList.business_date_start,
        business_date_end: this.querryList.business_date_end,
        state: this.querryList.state,
        sort_str: this.key || null,
        sort_type: this.order || null,
        type: this.clickIndex // 厂家类型 1=国内厂家 2=境外厂家
      }
      //
      if (this.$moment(data.business_date_start).valueOf() > this.$moment(data.business_date_end).valueOf()) {
        this.$Message.warning('截止有效期不得早于起始有效期，请重新选择截止有效期')
        return
      }
      this.$http.get(this.$api.factoryManage, data, true).then(res => {
        this.total = res.data.total
        this.listData = res.data.factorys
        this.listData.forEach((item, index) => {
          // 处理列表中的时间日期
          if (item.factory_business != '长期') {
            item.factory_business = this.$moment.unix(item.factory_business).format('YYYY-MM-DD')
          }
          item.factory_filing = this.$moment.unix(item.factory_filing).format('YYYY-MM-DD')
          item.factory_product = this.$moment.unix(item.factory_product).format('YYYY-MM-DD')

          if (item.factory_business == '1970-01-01') {
            item.factory_business = ''
          }
          if (item.factory_filing == '1970-01-01') {
            item.factory_filing = ''
          }
          if (item.factory_product == '1970-01-01') {
            item.factory_product = ''
          }
        })
      })
    },
    // 改变页码
    changePage(e) {
      this.pages.page = e
      this.getSearchProductFactory()
    },
    clickNav(type) {
      this.clickIndex = type
      this.pages.page = 1
      this.getSearchProductFactory()
    },
    ok() {
      localStorage.setItem('facttype', this.facttype)
      if(!this.facttype) {
        this.$Message.warning('请选择厂家类型')
        return
      }
      if (this.facttype == 2) {
        this.$router.push('/outlandManage')
      }
      if (this.facttype == 1) {
        this.$router.push('/manufacturerManage')
      }
      this.facttype = ''
      this.addzFacotryModel = false
    },
    cancel() {
      console.log('cancel')
    },
    gonext() {
      console.log('取下一步')
    },
    change(e) {
      console.log(this.facttype)
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = rightStr.search
    }
  }
}
</script>

<style scoped lang="less">
.foot {
  height: 36px;
  margin-top: 20px;
  text-align: right;
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;

  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }

  .topDiv {
    // padding: 20px 25px;
    padding: 20px 0 0 10px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      padding-left: 20px;
      min-height: 36px;

      .iviewIptWidth250 {
        margin-right: 20px;
      }

      .label {
        color: #000;
        font-size: 14px;
      }
    }
  }

  .topNavDiv {
    height: 42px;

    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }

    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}

.centeFont {
  margin: 0 10px;
  font-size: 14px;
  color: #000;
}
.iviewwidth200px {
  width: 200px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
